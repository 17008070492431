.app {
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.wrapper {
  width: calc(100vh * 1.777777778);
  height: 100vh;
  margin: 0 auto;
  position: relative;
}
img.rendering {
  display: block;
  height: 100vh;
  width: calc(100vh * 1.777777778);
}
.container {
  position: absolute;
  top: 45.05%;
  left: 39.03%;
  width: 21.7%;
}
.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  /* Player ratio: 16:9 (divide 9 by 16 = 0.5625) */
}
.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.play-btn {
  width: 3%;
  height: 5%;
  position: absolute;
  bottom: 11.5%;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: rgba(0, 144, 208, 0.5);
  border-radius: 3px;
  transition: all 0.2s ease-in;
}
.play-btn-1 {
  left: 34.3%;
}
.play-btn-2 {
  left: 39.1%;
}
.play-btn-3 {
  left: 46%;
}
.play-btn-4 {
  left: 50.8%;
}
.play-btn-5 {
  left: 57.7%;
}
.play-btn-6 {
  left: 62.5%;
}
.play-btn:hover {
  background-color: rgba(0, 88, 150, 0.5);
}

.btn {
  position: absolute;
  outline: none;
  color: white;
  /* min-width: 7%;
  min-height: 5%; */
  min-width: 6%;
  min-height: 3%;
  padding: 0.2rem 0.8rem;
  font-size: 1rem;
  text-align: center;
  cursor: pointer;
  display: block;
  background-color: #0090d0;
  border: 1px solid #0090d0;
  border-radius: 3px;
  transition: all 0.2s ease-in;
  top: 69%;
}
.btn:hover {
  background-color: #005896;
  border: 1px solid #005896;
}
.fullscreen-btn {
  right: 35.5%;
}
.screensaver-btn {
  left: 35.5%;
}

@media only screen and (orientation: portrait) {
  .btn {
    font-size: 3rem;
  }
}
@media only screen and (max-width: 2000px) and (orientation: portrait) {
  .btn {
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 1000px) and (orientation: portrait) {
  .btn {
    font-size: 1.4rem;
  }
}
@media only screen and (max-width: 900px) and (orientation: portrait) {
  .btn {
    font-size: 1.2rem;
  }
}
@media only screen and (max-width: 800px) and (orientation: portrait) {
  .btn {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 700px) and (orientation: portrait) {
  .btn {
    font-size: 0.85rem;
  }
}

@media only screen and (orientation: landscape) {
  .btn {
    font-size: 2rem;
  }
}
@media only screen and (max-width: 2100px) and (orientation: landscape) {
  .btn {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 1400px) and (orientation: landscape) {
  .btn {
    font-size: 0.85rem;
  }
}
@media only screen and (max-width: 900px) and (orientation: landscape) {
  .btn {
    font-size: 0.7rem;
  }
}
@media only screen and (max-width: 700px) and (orientation: landscape) {
  .btn {
    font-size: 0.6rem;
  }
}